var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"validacion",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('modal',{ref:"modalTipoRegistro",attrs:{"titulo":"Añadir país por tipo de registro","no-aceptar":"","adicional":"Guardar"},on:{"adicional":function($event){return _vm.PostRegistro(valid)}}},[_c('div',{staticClass:"row mx-0 justify-center"},[(!_vm.id)?_c('div',{staticClass:"col-9 my-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"pais"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v(" Seleccionar país ")]),_c('el-select',{staticClass:"w-100",model:{value:(_vm.idm_pais),callback:function ($$v) {_vm.idm_pais=$$v},expression:"idm_pais"}},_vm._l((_vm.paises),function(pais){return _c('el-option',{key:pais.id,attrs:{"label":pais.pais,"value":pais.id}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"col-9 my-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"porcentaje de comisión"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v(" Porcentaje de comisión ")]),_c('el-input',{staticClass:"w-100",model:{value:(_vm.comision_monto),callback:function ($$v) {_vm.comision_monto=$$v},expression:"comision_monto"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-9 my-3"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"vigencia de comisión"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"text-general f-14 pl-3"},[_vm._v(" Vigencia de comisión en meses ")]),_c('el-input',{staticClass:"w-100",model:{value:(_vm.comision_meses),callback:function ($$v) {_vm.comision_meses=$$v},expression:"comision_meses"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-9 pl-4 mb-4"},[_c('el-checkbox',{staticClass:"check-red",model:{value:(_vm.postulacion),callback:function ($$v) {_vm.postulacion=$$v},expression:"postulacion"}},[_vm._v(" "+_vm._s(_vm.$config.vendedor)+" requiere postulacion ")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }