<template>
    <ValidationObserver ref="validacion" v-slot="{ valid }">
        <modal ref="modalTipoRegistro" titulo="Añadir país por tipo de registro" @adicional="PostRegistro(valid)" no-aceptar adicional="Guardar">
            <div class="row mx-0 justify-center">
                <div v-if="!id" class="col-9 my-3">
                    <ValidationProvider v-slot="{errors}" rules="required" name="pais">
                        <p class="text-general f-14 pl-3">
                            Seleccionar país
                        </p>                    
                        <el-select v-model="idm_pais" class="w-100">
                            <el-option
                            v-for="pais in paises"
                            :key="pais.id"
                            :label="pais.pais"
                            :value="pais.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-9 my-3">
                    <ValidationProvider v-slot="{errors}" rules="required" name="porcentaje de comisión">
                        <p class="text-general f-14 pl-3">
                            Porcentaje de comisión
                        </p>
                        <el-input v-model="comision_monto" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-9 my-3">
                    <ValidationProvider v-slot="{errors}" rules="required" name="vigencia de comisión">
                        <p class="text-general f-14 pl-3">
                            Vigencia de comisión en meses
                        </p>
                        <el-input v-model="comision_meses" class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-9 pl-4 mb-4">
                    <el-checkbox v-model="postulacion" class="check-red">
                        {{$config.vendedor}} requiere postulacion
                    </el-checkbox>
                </div>
            </div>
        </modal>
    </ValidationObserver>
</template>

<script>
import TipoRegistro from '~/services/configurar/admin/tipoRegistro'
export default {
    data(){
        return {
            id: null,
            paises: [],
            idm_pais: '',
            comision_monto: '',
            comision_meses: '',
            postulacion: false
        }
    },
    mounted(){
        
    },
    methods: {
        toggle(datos = false){
            this.limpiarDatos()
            if(datos){
                this.id = datos.id
                this.idm_pais = datos.idm_pais
                if(datos.postulacion==1){
                    datos.postulacion = true
                }
                this.postulacion = datos.postulacion
                this.comision_monto = datos.comision_monto
                this.comision_meses = datos.comision_meses
            }else{
                this.id = null
            }
            this.$refs.modalTipoRegistro.toggle();
        },
        async getPaises(){
            try {
                const {data} = await TipoRegistro.GetPaises()     
                this.paises = data           
            } catch (e){
                this.error_catch(e)
            }
        },
        async PostRegistro(valid){
            try {
                if(valid){
                    let datos = {
                    id : this.id,
                    idm_pais : this.idm_pais,
                    postulacion : this.postulacion,
                    comision_monto : this.comision_monto,
                    comision_meses : this.comision_meses
                }
                const {data} = await TipoRegistro.PostRegistro(datos)
                this.limpiarDatos()
                this.toggle()
                this.$emit('guardado')
                }                
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiarDatos(){
            this.id = null
            this.idm_pais = ''
            this.postulacion = false
            this.comision_monto = ''
            this.comision_meses = ''
        }
    }
}
</script>

<style lang="scss" scoped>
</style>